html,
body,
#root {
    height: 100%;
    width: 100%;
    --vh: 1vh
}

body {
    margin: 0;
    font-family: "ffnettopro-reg", sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #fff;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

nav {
    height: calc(var(--vh) * 8);
}


